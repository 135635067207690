import { isEmpty, keyBy, orderBy } from 'lodash-es';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PrescriberMatchVerification } from '../../../core/models/prescriber-match-verification.model';
import { PrescriberMatchVerificationsService } from '../../../core/services/prescriber-match-verifications.service';
import { Capture } from '../../../core/models/capture.model';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { isPatientAttachmentVerificationFrozen } from '../../../core/lib/verification-utils';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ListProviderOffice } from 'app/core/models/list-provider-office.model';

class PrescriberMatchOption {
  constructor(
    public npi: string,
    public name: string,
    public specialty: string,
    public isPrescriber: boolean,
    public status: string,
    public verification: PrescriberMatchVerification,
    public frozen: boolean,
    public providerOffice: ProviderOffice | ListProviderOffice,
  ) {}
}

const resolveVerification = (npi: string, verificationsByNpi: object) => {
  if (!(npi in verificationsByNpi)) {
    const verification = new PrescriberMatchVerification();
    verification.npi = npi;
    return verification;
  } else {
    return verificationsByNpi[npi];
  }
};

@Component({
  selector: 'app-prescriber-match-form',
  templateUrl: './prescriber-match-form.component.html',
  styleUrls: ['./prescriber-match-form.component.scss'],
})
export class PrescriberMatchFormComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  showAdditionalDetails = false;
  additionalDetailsFrozen = false;
  capturePrescriberNotAtOffice = false;
  verifyingPrescribersNotAtOffice: { npi: string; name: string }[] = [];
  validationKey = ValidationKeys.prescriberMatchVerification;
  prescriberMatchOptions: PrescriberMatchOption[] = [];

  constructor(
    private prescriberMatchVerificationService: PrescriberMatchVerificationsService,
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService,
    private captureValidationService: CaptureValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'patientAttachment') {
        this.initPrescriberMatchVerifications();
      }

      if (inputName === 'capture') {
        this.refreshPrescriberMatchOptions();
      }
    });
  }

  onPrescriberVerificationChange(prescriberMatchVerification: PrescriberMatchVerification) {
    this.captureValidationService.clearError(this.validationKey);
    this.savePrescriberMatchVerification(prescriberMatchVerification);
  }

  onAdditionalTextChange() {
    this.updatePatientAttachment();
  }

  onPlusClick() {
    this.showAdditionalDetails = true;
  }

  onMinusClick() {
    this.showAdditionalDetails = false;
  }

  onNoteClick($event: MouseEvent) {
    $event.preventDefault();
  }

  private savePrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    if (prescriberMatchVerification.id) {
      this.updatePrescriberMatchVerification(prescriberMatchVerification);
    } else {
      this.createPrescriberMatchVerification(prescriberMatchVerification);
    }
  }

  private createPrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    this.prescriberMatchVerificationService
      .create(this.patientAttachment.id, this.capture.id, prescriberMatchVerification)
      .subscribe((pmv: PrescriberMatchVerification) => {
        this.handleSave(pmv);
      });
  }

  private updatePrescriberMatchVerification(prescriberMatchVerification: PrescriberMatchVerification) {
    this.prescriberMatchVerificationService
      .update(this.patientAttachment.id, prescriberMatchVerification.id, this.capture.id, prescriberMatchVerification)
      .subscribe((pmv: PrescriberMatchVerification) => {
        this.handleSave(pmv);
      });
  }

  private updatePatientAttachment() {
    this.patientAttachmentsService.update(this.patientAttachment).subscribe((pa: PatientAttachment) => {
      this.patientAttachmentsService.notifyPatientAttachmentChanged(pa);
    });
  }

  private handleSave(prescriberMatchVerification: PrescriberMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);

    if (!modifiedPatientAttachment.prescriberMatchVerifications) {
      modifiedPatientAttachment.prescriberMatchVerifications = [];
    }

    const paIndex = modifiedPatientAttachment.prescriberMatchVerifications.findIndex(
      pmv => pmv.npi === prescriberMatchVerification.npi
    );

    // creates and updates
    if (paIndex > -1) {
      modifiedPatientAttachment.prescriberMatchVerifications[paIndex] = prescriberMatchVerification;
    } else {
      modifiedPatientAttachment.prescriberMatchVerifications.push(prescriberMatchVerification);
    }

    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);

    // every npi should be in the option list
    const optionIndex = this.prescriberMatchOptions.findIndex(pmo => pmo.npi === prescriberMatchVerification.npi);

    if (optionIndex > -1) {
      this.prescriberMatchOptions[optionIndex].verification = prescriberMatchVerification;
    }
  }

  private initPrescriberMatchVerifications() {
    const prescriberMatchVerifications = this.patientAttachment.prescriberMatchVerifications || [];
    this.showAdditionalDetails = !isEmpty(this.patientAttachment.prescriberMatchVerificationNotes);
    this.prescriberMatchOptions = this.initPrescriberMatchOptions(prescriberMatchVerifications);

    if (!this.patientAttachment.ehrConsultNoteReference) {
      this.updatePrescriberOfficeFlags(prescriberMatchVerifications);
    }
  }

  private initPrescriberMatchOptions(prescriberMatchVerifications: PrescriberMatchVerification[]) {
    this.additionalDetailsFrozen = isPatientAttachmentVerificationFrozen(
      this.authService,
      this.capture,
      this.patientAttachment,
      null
    );

    const keyedPrescriberMatchOptions = {};
    const keyedPrescriberVerifications = keyBy(prescriberMatchVerifications, 'npi');

    this.createPrescriberMatchOptionsFromPmvs(
      keyedPrescriberMatchOptions,
      prescriberMatchVerifications,
      keyedPrescriberVerifications
    );

    this.createPrescriberMatchOptionsFromProvidersAtOffice(
      keyedPrescriberMatchOptions,
      keyedPrescriberVerifications
    );

    this.createPrescriberMatchOptionForEhrConsultNoteReference(
      keyedPrescriberMatchOptions,
      keyedPrescriberVerifications
    )

    return orderBy(
      Object.values(keyedPrescriberMatchOptions),
      ['isPrescriber', 'name'],
      ['desc', 'asc']
    ) as PrescriberMatchOption[];
  }

  private createPrescriberMatchOptionsFromPmvs(
    keyedPrescriberMatchOptions: { [npi: string]: PrescriberMatchOption },
    prescriberMatchVerifications: PrescriberMatchVerification[],
    keyedPrescriberVerifications: { [npi: string]: PrescriberMatchVerification }
  ) {
    const prescriber = this.capture.prescriber;

    // add the verifications using their own serialized provider data
    prescriberMatchVerifications.filter(pmv => pmv.npi !== prescriber.npi).forEach(pmv => {
      const resolvedVerification = resolveVerification(pmv.npi, keyedPrescriberVerifications);

      keyedPrescriberMatchOptions[pmv.npi] = new PrescriberMatchOption(
        pmv.npi,
        pmv.relatedProvider?.displayName,
        pmv.relatedProvider?.displaySpeciality,
        pmv.npi === prescriber.npi,
        pmv.relatedProvider?.relevantProviderOffice?.status,
        resolvedVerification,
        isPatientAttachmentVerificationFrozen(
          this.authService,
          this.capture,
          this.patientAttachment,
          resolvedVerification
        ),
        pmv.relatedProvider?.relevantProviderOffice
      );
    });
  }

  private createPrescriberMatchOptionsFromProvidersAtOffice(
    keyedPrescriberMatchOptions: { [npi: string]: PrescriberMatchOption },
    keyedPrescriberVerifications: { [npi: string]: PrescriberMatchVerification }
  ) {
    const prescriber = this.capture.prescriber;

    // add additional options using the office list
    this.patientAttachment.providersAtOffice.forEach(c => {
      if (!(c.npi in keyedPrescriberMatchOptions)) {
        const resolvedColleagueVerification = resolveVerification(c.npi, keyedPrescriberVerifications);

        keyedPrescriberMatchOptions[c.npi] = new PrescriberMatchOption(
          c.npi,
          c.displayName,
          c.displaySpeciality,
          c.npi === prescriber.npi,
          c.relevantProviderOffice?.status,
          resolvedColleagueVerification,
          isPatientAttachmentVerificationFrozen(
            this.authService,
            this.capture,
            this.patientAttachment,
            resolvedColleagueVerification
          ),
          c.relevantProviderOffice
        );
      }
    });
  }

  private createPrescriberMatchOptionForEhrConsultNoteReference(
    keyedPrescriberMatchOptions: { [npi: string]: PrescriberMatchOption },
    keyedPrescriberVerifications: { [npi: string]: PrescriberMatchVerification }
  ) {
    const prescriber = this.capture.prescriber;

    if (this.patientAttachment.ehrConsultNoteReference) {
      const resolvedColleagueVerification = resolveVerification(prescriber.npi, keyedPrescriberVerifications);

      keyedPrescriberMatchOptions[prescriber.npi] = new PrescriberMatchOption(
        prescriber.npi,
        prescriber.fullName,
        prescriber.displaySpecialty,
        true,
        null,
        resolvedColleagueVerification,
        isPatientAttachmentVerificationFrozen(
          this.authService,
          this.capture,
          this.patientAttachment,
          resolvedColleagueVerification
        ),
        null
      );
    }
  }

  private refreshPrescriberMatchOptions() {
    this.additionalDetailsFrozen = isPatientAttachmentVerificationFrozen(
      this.authService,
      this.capture,
      this.patientAttachment,
      null
    );

    this.prescriberMatchOptions.forEach(option => {
      option.frozen = isPatientAttachmentVerificationFrozen(
        this.authService,
        this.capture,
        this.patientAttachment,
        option.verification
      );
    });
  }

  private updatePrescriberOfficeFlags(prescriberMatchVerifications: PrescriberMatchVerification[]) {
    this.capturePrescriberNotAtOffice = false;
    this.verifyingPrescribersNotAtOffice = [];

    const officeProviderNpis = this.patientAttachment.providersAtOffice.map(c => c.npi);

    if (this.capture.verified) {
      this.verifyingPrescribersNotAtOffice = prescriberMatchVerifications
        .filter(pmv => pmv.matches && !officeProviderNpis.some(npi => pmv.npi === npi))
        .map(pmv => ({ npi: pmv.npi, name: pmv.relatedProvider?.displayName }));
    } else {
      this.capturePrescriberNotAtOffice =
        !officeProviderNpis.some(npi => this.capture.prescriber.npi === npi);
    }
  }
}
