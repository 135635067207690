import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { ClientTaskRejectionReason } from 'app/core/enums/client-task-rejection-reason.enum';
import { ProviderSpecialtyService } from 'app/core/services/provider-specialty.service';
import { ReferralSpecialty } from 'app/core/models/referral-specialty.model';

@Component({
  selector: 'app-client-referral-task-option-reason-additional-fields',
  templateUrl: './client-referral-task-option-reason-additional-fields.component.html'
})
export class ClientReferralTaskOptionReasonAdditionalFieldsComponent implements OnInit {
  @Input() reason: ReferralVerificationReason | ClientTaskRejectionReason;
  @Input() disabled: boolean;
  @Input() model: { [property: string]: string };

  @Output() modelChange = new EventEmitter<{ [property: string]: string }>();

  referralSpecialties: ReferralSpecialty[];

  verificationReasons = ReferralVerificationReason;

  constructor(
    private providerSpecialtyService: ProviderSpecialtyService
  ) {}

  ngOnInit(): void {
    this.providerSpecialtyService.getReferralSpecialties().subscribe(
      specialties => this.referralSpecialties = specialties
    )
  }

  onValueChange() {
    this.modelChange.emit(this.model);
  }
}
