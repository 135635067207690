<div *ngIf="reason === verificationReasons.specialityReferral">
  <div>Choose <strong>closest</strong> referral specialty (required)</div>
  <ng-select
    name="choiceSpecialityReferralSpeciality"
    (change)="onValueChange()"
    [items]="referralSpecialties"
    bindValue="name"
    bindLabel="name"
    [clearable]="false"
    [(ngModel)]="model.speciality" />

</div>

<div *ngIf="reason === verificationReasons.other">
  <div>If other, please specify (required)</div>
  <textarea
    class="form-control"
    (change)="onValueChange()"
    name="choiceOtherReasonNote"
    [(ngModel)]="model.reasonNote"></textarea>
</div>
