import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { ClientReferralTaskChoice } from '../../../core/enums/client-referral-task-choice.enum';
import { ClientReferralTaskChoiceOption, choiceOptions } from '../../../core/options/client-referral-task-choice-opts';
import { ClientReferralTasksService } from '../../../core/services/client-referral-tasks.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ClientTaskRejectionReason } from '../../../core/enums/client-task-rejection-reason.enum';
import { getSelectedValues, getKeyedSelectedValues } from '../../../core/lib/enum-utils';

interface ReasonAttributes {
  [ReferralVerificationReason.other]: { reasonNote: string };
  [ReferralVerificationReason.specialityReferral]: { speciality: string };
}
interface FormModel {
  choice: ClientReferralTaskChoice,
  reasonSelections: { [reason in ReferralVerificationReason]?: boolean },
  reasonAttributes: ReasonAttributes
}

@Component({
  selector: 'app-client-referral-task-options',
  templateUrl: './client-referral-task-options.component.html',
  styleUrls: ['./client-referral-task-options.component.scss'],
})
export class ClientReferralTaskOptionsComponent implements OnInit, OnChanges {
  @Input() task: ClientReferralTask;
  @Input() formDisabled: boolean;
  @Output() taskChange = new EventEmitter<ClientReferralTask>();

  choiceOptions: ClientReferralTaskChoiceOption[] = [];
  disabled = false;
  updatingChoice = false;

  choices = ClientReferralTaskChoice;
  verificationReasons = ReferralVerificationReason;

  formModel: FormModel = {
    choice: null,
    reasonSelections: {},
    reasonAttributes: {
      [this.verificationReasons.other]: { reasonNote: null },
      [this.verificationReasons.specialityReferral]: { speciality: null }
    }
  };

  constructor(
    private clientReferralTasksService: ClientReferralTasksService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.init();
    this.initChoiceOptions();
  }

  ngOnChanges() {
    this.init();
  }

  onChoiceClick($event, choice: ClientReferralTaskChoice) {
    if (!this.task.frozen && this.formModel.choice !== choice) {
      this.formModel.choice = choice;
      this.formModel.reasonSelections = {};
      this.updateFormModelReasonAttributes();
      this.updateChoice(choice, this.filterSelectedReasons(choice), this.formModel.reasonAttributes);
    }
  }

  onVerificationReasonChange(
    $event: Event,
    choice: ClientReferralTaskChoice,
    reason: ReferralVerificationReason | ClientTaskRejectionReason
  ) {
    const input = $event.target as HTMLInputElement;
    this.updateReasons(choice, reason, input.checked);
  }

  onAdditionalFieldsChange() {
    const choice = this.formModel.choice;
    this.updateChoice(choice, this.filterSelectedReasons(choice), this.formModel.reasonAttributes);
  }

  onRejectionReasonChange(
    { reason, checked }: { reason: ClientTaskRejectionReason; checked: boolean },
    choice: ClientReferralTaskChoice
  ) {
    this.updateReasons(choice, reason, checked);
  }

  onRejectionReasonsClear() {
    this.formModel.reasonSelections = {};
    this.updateChoice(this.formModel.choice, null, this.formModel.reasonAttributes);
  }

  private updateChoice(choice: string, choiceReasons: string[], reasonAttributes: ReasonAttributes) {
    if (!this.updatingChoice) {
      this.updatingChoice = true;
      const { id, notes, rejectedPrescriptionIds } = this.task;

      const choiceOtherReasonNote =
        reasonAttributes[ReferralVerificationReason.other].reasonNote;

      const choiceSpecialityReferralSpeciality =
        reasonAttributes[ReferralVerificationReason.specialityReferral].speciality;

      this.clientReferralTasksService
        .updateChoices(id, choice, choiceReasons, choiceOtherReasonNote, choiceSpecialityReferralSpeciality)
        .subscribe(task => {
          this.taskChange.emit(task);
          this.updatingChoice = false;
        });
    }
  }

  private updateReasons(
    choice: ClientReferralTaskChoice,
    reason: ReferralVerificationReason | ClientTaskRejectionReason,
    checked: boolean
  ) {
    if (!this.task.frozen) {
      // the no choice is only allowed one selection
      if (choice === ClientReferralTaskChoice.no) {
        this.formModel.reasonSelections = { [reason]: checked };
      } else {
        this.formModel.reasonSelections[reason] = checked;
      }

      const selectedReasons = this.filterSelectedReasons(choice);
      this.formModel.choice = selectedReasons.length > 0 ? choice : null;
      this.updateFormModelReasonAttributes();

      this.updateChoice(this.formModel.choice, selectedReasons, this.formModel.reasonAttributes);
    }
  }

  private initSelectedReasons(reasons, choice) {
    if (choice === ClientReferralTaskChoice.yes) {
      return getKeyedSelectedValues(reasons, ReferralVerificationReason);
    } else if (choice === ClientReferralTaskChoice.no) {
      return getKeyedSelectedValues(reasons, ClientTaskRejectionReason);
    } else {
      return {};
    }
  }

  private initReasonAttributes(task) {
    const { choiceOtherReasonNote, choiceSpecialityReferralSpeciality } = this.task;

    return {
      [this.verificationReasons.other]: { reasonNote: choiceOtherReasonNote },
      [this.verificationReasons.specialityReferral]: { speciality: choiceSpecialityReferralSpeciality }
    };
  }

  private init() {
    const { choice } = this.task;
    const reasonSelections = this.initSelectedReasons(this.task.choiceReasons || [], choice);
    const reasonAttributes = this.initReasonAttributes(this.task);

    this.formModel = { choice, reasonSelections, reasonAttributes };
    this.disabled = this.formDisabled || this.task.frozen;

    this.updateFormModelReasonAttributes();
  }

  private filterSelectedReasons(choice) {
    if (choice === ClientReferralTaskChoice.yes) {
      return getSelectedValues(this.formModel.reasonSelections, ReferralVerificationReason);
    } else if (choice === ClientReferralTaskChoice.no) {
      return getSelectedValues(this.formModel.reasonSelections, ClientTaskRejectionReason);
    } else {
      return [];
    }
  }

  private updateFormModelReasonAttributes() {
    const reasonAttributes = this.formModel.reasonAttributes;

    if (!this.shouldHaveChoiceOtherReasonNote()) {
      reasonAttributes[this.verificationReasons.other].reasonNote = null;
    }

    const specialityReferralReasonAttributes = reasonAttributes[this.verificationReasons.specialityReferral];

    if (this.shouldHaveChoiceSpecialityReferralSpeciality()) {
      if (!specialityReferralReasonAttributes.speciality) {
        specialityReferralReasonAttributes.speciality = this.task.prescriber?.recommendedReferralSpecialty;
      }
    } else {
      specialityReferralReasonAttributes.speciality = null;
    }
  }

  private shouldHaveChoiceOtherReasonNote() {
    return this.formModel.choice === ClientReferralTaskChoice.yes &&
      this.formModel.reasonSelections[this.verificationReasons.other];
  }

  private shouldHaveChoiceSpecialityReferralSpeciality() {
    return this.formModel.choice === ClientReferralTaskChoice.yes &&
      this.formModel.reasonSelections[this.verificationReasons.specialityReferral];
  }

  private initChoiceOptions() {
    let filteredOptions = choiceOptions;

    if (!this.authService.isCaptureAdminUser && !this.authService.isInternalConsultantUser) {
      filteredOptions = filteredOptions.filter(opt => opt.value !== ClientReferralTaskChoice.prescriberIsCeProvider);
    }

    this.choiceOptions = filteredOptions;
  }
}
