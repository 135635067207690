// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  maintenance: false,
  mfaRequiredForAllUsers: true,
  localApiServer: false,
  idleTimeoutSeconds: 1200,
  debugUserIdle: false,
  debugUserTiming: true,
  debugRouter: false,
  awsRegion: 'us-west-2',
  sentryDsn: 'https://fff8d1b7d8bc4209b21111a087f09301@sentry.io/1449790',
  captureApi: {
    url: 'https://t40b-r-qa.par8o.com/api/v1',
  },
  helpscoutApi: {
    token: '2ab8e508-c60b-4dc4-b370-e903091ced66',
  },
  pendo: {
    enabled: true,
    accountIdentifier: 'QA'
  },
  featureData: {
    consultNotePages: true,
    usePdfKitViewer: true,
  },
  psPdfKit: {
    licenseKey: 'BtX-Y-lIRB5nehdw1s3QtwbB0kUtyt8p4yc0QxfJaMxWrsamQpO3RrcDaOgMt3qhyGAqraUfbmApFA0HzCiSCgx_7jP_uPVCYbHxEuNthlDiVJCtfp2KyVAPwqWWqv6sPzmYeBgxuL1aGri2tnHDTbEAGfYHDcD1jPRGAIih_vicHO5MIfl5RJsDBcX6cAtnhOQZkCZV9TFWXpnaA1IuvpK8x5JTN1Rwrkh3Nqa6nuXYxpxl9Gzk4EwX9S23KaG5QHPTAEbRK7xx_lxJDeqlnEIJfYN_6IcYzUPSYeXdA5yiJ-SNsj1-FK0X2FPOJV_b8xG9UlsrH1dI-37o77GEfEHE7j1v-uBrTyAD9xZtvpYnA1ZXcQ25rl_jRiKVjUNteGMXU3KzU4sWK89rog7S8UKa5_D7yzD0RvUk7JF1aRTTJkVkzqhN1gdUmzRKoQa8G9WPjrP5YnTSo0nh_xzH1vt5AwYJekOgAJBGRVotmK1tFZ5VBBQV-a7IiNohSr0y7DYOyu1aCHxX_g7wn0RLtj_IrKtKaohQynJe_WdUaPbGCdMEG659h0QpiVT04SCeXFB6UCA_Cw8bOZn_PZnUUrQAbk0wVO8eAFWkwmiTFt1OSNIpyyj3KaVjyOgkaW64'
  },
};
