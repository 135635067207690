<div class="card mt-1 bg-light">
  <div class="card-body">
    <h4>Patient Documents</h4>
    <div *ngIf="!loading; else: spinner">

      <app-verification-validation [key]="patientAttachmentValidationKey" />

      <section *ngIf="canModifySelectedAttachment" class="row mt-1">
        <div class="col-4">
          <div class="row">
            <div class="col-6">
              <strong>Consult Notes</strong>
            </div>

            <div class="col-6 add-consult-note-reference"
              *ngIf="capture.ehrAccess && !showAddEhrConsultNoteReferenceForm">
              <a href="javascript:;" (click)="onAddEhrConsultNoteReferenceClick()">
                Add Consult Note Reference
              </a>
            </div>
          </div>

          <app-patient-document-selector
            [capture]="capture"
            [selectedAttachment]="selectedAttachment"
            [allPatientAttachments]="allPatientAttachments"
            (selectionChange)="onSelectedAttachmentChange($event)"
          />
        </div>

        <div class="col-8 align-self-end" *ngIf="attachmentSelectedAndVisible">
          <div class="row">
            <div class="col-6">
              <app-associate-patient-document
                [capture]="capture"
                [selectedAttachment]="selectedAttachment"
                [isAssociated]="selectedAttachmentIsAssociated"
                (associatedAttachmentChange)="onAssociatedAttachmentChange($event)"
              />
            </div>
            <div class="col-6">
              <app-mark-patient-document-as-invalid
                [capture]="capture"
                [selectedAttachment]="selectedAttachment"
                (markedAsInvalidChanged)="onMarkedAttachmentAsInvalidChange($event)"
              />
            </div>
          </div>
          <div class="alert alert-danger text-danger ms-1 mt-1 p-1" *ngIf="serverFailure">
            <strong>
              A server error occurred while modifying the selected consult note. Please try again or contact support.
            </strong>
            <div class="server-errors mt-1" *ngIf="serverFailure.error">
              <div>{{ serverFailure.error.summary }}</div>
              <ul class="mt-2">
                <li *ngFor="let serverErrorDetail of serverFailure.error.details">{{ serverErrorDetail }}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section *ngIf="!canModifySelectedAttachment">
        <p *ngIf="attachmentSelectedAndVisible && !selectedAttachment.ehrConsultNoteReference">
          <a href="download" (click)="onDownloadAttachmentClick($event, selectedAttachment)">
            {{ selectedAttachment | patientAttachmentDisplayName }}
          </a>
          <a routerLink="/capture-admin/consult-notes/{{ selectedAttachment.id }}"
            target="_blank"
            title="Open consult note in new tab">
            <fa-icon [icon]="['fas', 'external-link-alt']" />
          </a>
          was used to support this claim. It was imported on
          <strong>{{ selectedAttachment.createdAt | date: 'M/d/yyyy' }}</strong>
        </p>

        <p *ngIf="attachmentSelectedAndVisible && selectedAttachment.ehrConsultNoteReference">
          <strong>{{ selectedAttachment | patientAttachmentDisplayName }}</strong>
          was used to support this claim.
        </p>

        <div *ngIf="!selectedAttachment">No document was used to support this claim</div>
      </section>

      <hr class="mb-2" />

      <section *ngIf="attachmentSelectedAndVisible">
        <app-patient-document
          [capture]="capture"
          [selectedAttachment]="selectedAttachment" />
      </section>

      <div class="col-8" *ngIf="showAddEhrConsultNoteReferenceForm">
        <app-ehr-consult-note-reference-new
          [patient]="capture.patient"
          (newPatientAttachment)="onNewPatientAttachment($event)"
          (canceled)="onAddEhrConsultNoteReferenceCanceled()" />
      </div>
    </div>

    <app-outreach-attempt-evidence-section
      [capture]="capture"
      (newOutreachAttemptEvidence)="onNewOutreachAttemptEvidence($event)" />
  </div>
</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
