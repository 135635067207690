<p>
  Select the offices(s) for {{ prescriber.fullName }} with relevant outreach attempts:
</p>

<table class="table table-sm">
  <thead>
    <tr>
      <th>Office Details</th>
      <th>Fax Status</th>
      <th>Provider Fax Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let providerOffice of selectedProviderOffices; let i = index">
      <td>
        <app-office [office]="providerOffice.office" />
      </td>
      <td>
        {{ keyedOfficeStatusOptions[providerOffice.office.status]?.display }}
      </td>
      <td>
        {{ keyedProviderOfficeStatusOptions[providerOffice.status]?.display }}
      </td>
    </tr>
  </tbody>
</table>

<div class="mt-2">
  <div>
    Effective Date:
  </div>
  <div>
    {{ outreachAttemptEvidence.lastOutreachDate | date: 'MM/dd/yyyy' }}
  </div>
</div>

<div class="mt-2">
  <div>
    Justification:
  </div>
  <div>
    {{ outreachAttemptEvidence.notes }}
  </div>
</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
