import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PatientAttachment } from '../models/patient-attachment.model';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { ApiService } from './api.service';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { ListProvider } from '../models/list-provider.model';
import { MatchingPrescriberDrug } from '../models/matching-prescriber-drug.model';
import { MatchingPatientPrescriber } from '../models/matching-patient-prescriber.model';
import { PatientAttachmentHistoryItem } from 'app/core/models/patient-attachment-history-item.model';
import { SubmitConsultNoteResult } from '../models/submit-consult-note-result.model';
import { Capture } from '../models/capture.model';
import { SpecialistEncounterVerification } from '../models/specialist-encounter-verification.model';
import { ClientConsultNoteFilters } from '../models/user-settings/client-consult-note-settings.model';
import { EhrConsultNoteReference } from '../models/ehr-consult-note-reference.model';

export class PatientAttachmentChangedEvent {
  constructor(public patientAttachment: PatientAttachment) {}
}

export class MatchingPrescriberDrugsChangedEvent {
  constructor(public matchingPrescriberDrugs: MatchingPrescriberDrug[]) {}
}

export class PagedPatientAttachments implements PagedResults {
  patientAttachments: PatientAttachment[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.patientAttachments;
  }
}

@Injectable()
export class PatientAttachmentsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  private patientAttachmentChangedSource = new Subject<PatientAttachmentChangedEvent>();
  patientAttachmentChanged = this.patientAttachmentChangedSource.asObservable();

  private matchingPrescriberDrugsChangedSource = new Subject<MatchingPrescriberDrugsChangedEvent>();
  matchingPrescriberDrugsChanged = this.matchingPrescriberDrugsChangedSource.asObservable();

  get(id: number | string): Observable<PatientAttachment> {
    return this.http.get<PatientAttachment>(`${environment.captureApi.url}/patient_attachments/${id}`);
  }

  getUrl(id: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${environment.captureApi.url}/patient_attachments/${id}/url`);
  }

  getStatus(id: number): Observable<{ status: string; completeForSubmit: boolean }> {
    return this.http.get<{ status: string; completeForSubmit: boolean }>(
      `${environment.captureApi.url}/patient_attachments/${id}/status`
    );
  }

  getDirectMessageList(
    filters: ClientConsultNoteFilters,
    page: number,
    pageSize: number
  ): Observable<PagedPatientAttachments> {
    const implicitFilters = {
      verifying: 'yes',
      ehr_reference: 'no',
      sent_via_direct: 'yes',
      sort_col: 'sent_via_direct_order'
    };
    return this.getFilteredList(implicitFilters, filters, page, pageSize);
  }

  getManualUploadList(
    filters: ClientConsultNoteFilters,
    page: number,
    pageSize: number
  ): Observable<PagedPatientAttachments> {
    const implicitFilters = { verifying: 'yes', ehr_reference: 'no', sort_col: 'ehr_upload_order' };
    return this.getFilteredList(implicitFilters, filters, page, pageSize);
  }

  getPatientList(patientId: number): Observable<PagedPatientAttachments> {
    return this.http.get<PagedPatientAttachments>(`${environment.captureApi.url}/patients/${patientId}/attachments`);
  }

  getPatientPrescriberList(
    patientId: number,
    prescriberId: number,
    attached: boolean = true,
    confirmed: boolean = true
  ): Observable<PagedPatientAttachments> {
    const queryParams: URLSearchParams = new URLSearchParams();
    queryParams.append('prescriber_id', prescriberId.toString());

    if (attached) {
      queryParams.append('attached', 'yes');
    }

    if (confirmed) {
      queryParams.append('confirmed', 'yes');
    }

    return this.http.get<PagedPatientAttachments>(
      `${environment.captureApi.url}/patients/${patientId}/attachments?${queryParams.toString()}`
    );
  }

  getPatientAttachmentHistoryItems(id: number): Observable<PatientAttachmentHistoryItem[]> {
    return this.http.get<PatientAttachmentHistoryItem[]>(
      `${environment.captureApi.url}/patient_attachments/${id}/history_items`
    );
  }

  getVerifyingCaptures(id: number): Observable<Capture[]> {
    return this.http.get<Capture[]>(
      `${environment.captureApi.url}/patient_attachments/${id}/verifying_captures`
    );
  }

  updateSpecialistEncounterVerifications(id: number,
                                         sev: SpecialistEncounterVerification[]): Observable<PatientAttachment> {
    return this.http.patch<PatientAttachment>(
      `${environment.captureApi.url}/patient_attachments/${id}/update_specialist_encounter_verifications`, {
      specialistEncounterVerifications: sev
    });
  }

  create(patientId: number, signedBlobId: string): Observable<PatientAttachment> {
    return this.http.post<PatientAttachment>(`${environment.captureApi.url}/patients/${patientId}/attachments`, {
      signed_id: signedBlobId,
    });
  }

  createReference(patientId: number, ehrConsultNoteReference: EhrConsultNoteReference): Observable<PatientAttachment> {
    return this.http.post<PatientAttachment>(`${environment.captureApi.url}/patients/${patientId}/attachments/create_reference`, {
      ehrConsultNoteReference: {
        name: ehrConsultNoteReference.name,
        notes: ehrConsultNoteReference.notes
      }
    });
  }

  update(patientAttachment: PatientAttachment): Observable<PatientAttachment> {
    const { medicationsPage, prescriberMatchVerificationNotes,
            selectedSpecialty, selectedSpecialtyAdditionalDetails } = patientAttachment;

    return this.http.patch<PatientAttachment>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachment.id}`,
      { patientAttachment: { medicationsPage, prescriberMatchVerificationNotes,
                             selectedSpecialty, selectedSpecialtyAdditionalDetails } }
    );
  }

  toggleEhrUpload(patientAttachment: PatientAttachment, uploaded: boolean): Observable<PatientAttachment> {
    return this.http.patch<PatientAttachment>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachment.id}/ehr_upload`,
      { uploaded }
    );
  }

  markAsInvalid(patientAttachmentId: number, invalid: boolean, updateCaptures = false): Observable<PatientAttachment> {
    return this.http.patch<PatientAttachment>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/mark_as_invalid`,
      { markAsInvalid: { invalid, updateCaptures } }
    );
  }

  submit(patientAttachment: PatientAttachment): Observable<SubmitConsultNoteResult> {
    return this.http.patch<SubmitConsultNoteResult>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachment.id}/submit`,
      {}
    );
  }

  backfillOcr(patientAttachmentId: number): Observable<PatientAttachment> {
    return this.http.patch<PatientAttachment>(
      `${environment.captureApi.url}/patient_attachments/${patientAttachmentId}/backfill_ocr`,
      {}
    );
  }

  getRelevantProviders(
    id: number
  ): Observable<{
    multipleSpecialtiesAtFaxedOffice: boolean;
    providersAtOfficeWithPrescriptions: ListProvider[];
    providersAtOfficeWithoutPrescriptions: ListProvider[];
    providersAtOtherOfficesWithPrescriptions: ListProvider[];
  }> {
    return this.http.get<{
      multipleSpecialtiesAtFaxedOffice: boolean;
      providersAtOfficeWithPrescriptions: ListProvider[];
      providersAtOfficeWithoutPrescriptions: ListProvider[];
      providersAtOtherOfficesWithPrescriptions: ListProvider[];
    }>(`${environment.captureApi.url}/patient_attachments/${id}/relevant_providers`);
  }

  getMatchingPrescriberDrugs(id: number): Observable<MatchingPrescriberDrug[]> {
    return this.http.get<MatchingPrescriberDrug[]>(
      `${environment.captureApi.url}/patient_attachments/${id}/matching_prescriber_drugs`
    );
  }

  getMatchingPatientPrescribers(id: number): Observable<MatchingPatientPrescriber[]> {
    return this.http.get<MatchingPatientPrescriber[]>(
      `${environment.captureApi.url}/patient_attachments/${id}/matching_patient_prescribers`
    );
  }

  notifyPatientAttachmentChanged(patientAttachment: PatientAttachment) {
    this.patientAttachmentChangedSource.next(new PatientAttachmentChangedEvent(patientAttachment));
  }

  notifyMatchingPrescriberDrugsChanged(matchingPrescriberDrugs: MatchingPrescriberDrug[]) {
    this.matchingPrescriberDrugsChangedSource.next(new MatchingPrescriberDrugsChangedEvent(matchingPrescriberDrugs));
  }

  private getFilteredList(implicitFilters: object, filters: ClientConsultNoteFilters, page: number, pageSize: number) {
    const rawParams = { ...implicitFilters, page, per_page: pageSize };
    const queryParams: URLSearchParams = new URLSearchParams();

    Object.keys(rawParams).forEach(k => queryParams.append(k, rawParams[k]));

    if (filters.selectedClientIds && filters.selectedClientIds.length > 0) {
      filters.selectedClientIds.forEach(v => queryParams.append('client_ids[]', v.toString()));
    }

    if (filters.patientFirstName) {
      queryParams.append('patient_first_name', filters.patientFirstName);
    }

    if (filters.patientLastName) {
      queryParams.append('patient_last_name', filters.patientLastName);
    }

    if (filters.patientDob) {
      queryParams.append('patient_dob', filters.patientDob);
    }

    return this.http.get<PagedPatientAttachments>(
      `${environment.captureApi.url}/patient_attachments?${queryParams.toString()}`
    );
  }
}
