import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { OutreachAttemptEvidence } from 'app/core/models/outreach-attempt-evidence.model';

@Component({
  selector: 'app-outreach-attempt-evidence-section',
  templateUrl: './outreach-attempt-evidence-section.component.html',
  styleUrls: ['./outreach-attempt-evidence-section.component.scss'],
})
export class OutreachAttemptEvidenceSectionComponent implements OnInit {
  @Input() capture: Capture;

  @Output() newOutreachAttemptEvidence = new EventEmitter<OutreachAttemptEvidence>();

  outreachAttemptEvidenceEnabled = false;
  showAddOutreachAttemptEvidenceForm = false;

  ngOnInit() {
    this.outreachAttemptEvidenceEnabled = this.capture.client.outreachAttemptEvidenceEnabled;
  }

  onAddOutReachAttemptEvidenceClick() {
    this.showAddOutreachAttemptEvidenceForm = true;
  }

  onAddOutReachAttemptEvidenceCanceled() {
    this.showAddOutreachAttemptEvidenceForm = false;
  }

  onNewOutreachAttemptEvidence(newOutreachAttemptEvidence: OutreachAttemptEvidence) {
    this.showAddOutreachAttemptEvidenceForm = false;

    this.newOutreachAttemptEvidence.emit(newOutreachAttemptEvidence);
  }

}
