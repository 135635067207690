<form *ngIf="patientMatchVerification">
  <app-verification-validation [key]="validationKey">
    <div>Does patient in consult note match patient on claim and CE encounter?</div>
    <div class="form-inline">
      <div class="form-check form-check-inline">
        <input
          id="patientMatchVerificationMatchesYes"
          name="patientMatchVerificationMatchesYes"
          class="form-check-input"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onPatientMatchesChange()"
          [(ngModel)]="patientMatchVerification.matches"
          [value]="true"
        />
        <label class="form-check-label" for="patientMatchVerificationMatchesYes">Yes</label>
      </div>
      <div class="form-check form-check-inline mx-2">
        <input
          id="patientMatchVerificationMatchesNo"
          name="patientMatchVerificationMatchesNo"
          class="form-check-input"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onPatientMatchesChange()"
          [(ngModel)]="patientMatchVerification.matches"
          [value]="false"
        />
        <label class="form-check-label" for="patientMatchVerificationMatchesNo">No</label>
      </div>
    </div>
  </app-verification-validation>
</form>
<div class="mb-1 mt-2">
  <app-additional-details-input
    [frozen]="verificationFrozen"
    [(value)]="patientMatchVerification.notes"
    (valueChange)="onAdditionalTextChange()"
    [rows]="5"
  />
</div>
