<div class="consult-note-document">
  <div class="row mt-1 g-0">
    <div id="questionsWrapper" class="col-4 pe-1 auto-resize-height">
      <div id="questionsNavbar" class="bg-dark mb-1 z-1">
        <div class="row g-0">
          <div class="col-8">
            <div class="question-navlinks verifications">
              <div class="ps-1">
                <a
                  href="#selectSpecialty"
                  title="Confirm Consult Note Specialty"
                  (click)="onVerificationLinkClick($event, 'selectSpecialty')"
                >
                  <mat-icon>medical_information</mat-icon>
                </a>
              </div>
              <div>
                <a
                  href="#validatePatientMatch"
                  title="Validate Patient Match"
                  (click)="onVerificationLinkClick($event, 'validatePatientMatch')"
                >
                  <mat-icon>perm_identity</mat-icon>
                </a>
              </div>
              <div>
                <a
                  href="#validatePrescriberMatch"
                  title="Validate Prescriber Match"
                  (click)="onVerificationLinkClick($event, 'validatePrescriberMatch')"
                >
                  <mat-icon>groups</mat-icon>
                </a>
              </div>
              <div>
                <a
                  href="#validateSpecialistEncounterMatch"
                  title="Validate Specialist Encounter Match"
                  (click)="onVerificationLinkClick($event, 'validateSpecialistEncounterMatch')"
                >
                  <mat-icon>event</mat-icon>
                </a>
              </div>
              <div>
                <a
                  href="#validateDrugMatch"
                  title="Validate Drug Match"
                  (click)="onVerificationLinkClick($event, 'validateDrugMatch')"
                >
                  <mat-icon>local_pharmacy</mat-icon>
                </a>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="question-navlinks expand-collapse">
              <div>
                <a href="#collapseAll" title="Collapse All" (click)="onCollapseAllClick($event)">
                  <mat-icon>unfold_less</mat-icon>
                </a>
              </div>
              <div>
                <a href="#expandAll" title="Expand All" (click)="onExpandAllClick($event)">
                  <mat-icon>unfold_more</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="questions">
        <app-consult-note-select-specialty
          #selectSpecialty
          [patientAttachment]="patientAttachment"
        />
        <app-validate-patient-match
          #validatePatientMatch
          [patientAttachment]="patientAttachment"
        />
        <app-validate-prescriber-match
          #validatePrescriberMatch
          [patientAttachment]="patientAttachment"
        />
        <app-validate-specialist-encounter
          #validateSpecialistEncounter
          [patientAttachment]="patientAttachment"
        />
        <app-validate-drug-match
          #validateDrugMatch
          [patientAttachment]="patientAttachment" />
      </div>
    </div>
    <div class="col-8">
      <ng-container *ngIf="patientAttachment.status !== 'in_ocr'">
        <div class="row g-0">
          <ul class="col nav nav-tabs bg-dark ps-1 pt-1">
            <li class="nav-item bg-dark">
              <button type="button" class="nav-link active" id="consult-note-tab" data-bs-toggle="tab" data-bs-target="#consult-note-pdf">Consult Note</button>
            </li>
            <li class="nav-item bg-dark">
              <button type="button" class="nav-link" id="summary-tab" data-bs-toggle="tab" data-bs-target="#consult-note-summary">Summary and OCR</button>
            </li>
          </ul>
          <div class="col nav-tabs g-0 text-end bg-dark pt-1 pe-2">
            <app-consult-note-ocr-backfill *ngIf="canAdmin"
              [patientAttachment]="patientAttachment"
            />
            <app-consult-note-validator *ngIf="canAdmin"
              [patientAttachment]="patientAttachment"
              (markedAsInvalidChanged)="onMarkedAsInvalidChanged($event)"
            />
          </div>
        </div>
        <div class="tab-content">
          <div class="tab-pane active show" id="consult-note-pdf">
            <app-pdf-file-viewer *ngIf="!usePdfKitViewer"
              [pdfFile]="patientAttachment"
              [pdfFileType]="pdfFileType"
              [initialPage]="initialPage"
              (pageChange)="onPageChange($event)">
              <button
                type="button"
                class="btn btn-sm"
                [ngClass]="{
                  'btn-danger': !patientAttachment.markedAsInvalid,
                  'btn-success': patientAttachment.markedAsInvalid
                }"
                [disabled]="markAsInvalidDisabled || markingAsInvalid"
                (click)="onMarkAsInvalidClick($event)"
              >
                <fa-icon [icon]="['fas', 'pen']" />
                <span [hidden]="markingAsInvalid">
                  <span [hidden]="patientAttachment.markedAsInvalid">Mark As INVALID</span>
                  <span [hidden]="!patientAttachment.markedAsInvalid">Mark As VALID</span>
                </span>
                <span [hidden]="!markingAsInvalid">
                  <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" /> Saving
                </span>
              </button>
            </app-pdf-file-viewer>
            <app-pdfkit-file-viewer *ngIf="usePdfKitViewer"
              class="card"
              height="70vh"
              [pdfFile]="patientAttachment"
              [pdfFileType]="pdfFileType"
              (urlChange)="onUrlChange($event)"
            />
          </div>
          <div class="tab-pane auto-resize-height" id="consult-note-summary">
            <app-consult-note-summary [resultSet]="patientAttachment.patientAttachmentLlmResultSet" />
            <app-ocr-result-text
              [patientAttachment]="patientAttachment" />
          </div>
        </div>
      </ng-container>
      <div *ngIf="patientAttachment.status === 'in_ocr'" class="mt-2 w-100 fw-bold text-center">
        Please wait for system to finish processing to view document.
      </div>
    </div>
  </div>
</div>
