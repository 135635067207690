import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Capture } from 'app/core/models/capture.model';
import { CaptureService } from 'app/core/services/capture.service';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { NavigationService } from 'app/core/services/navigation.service';
import { UserTimingService } from 'app/core/services/user-timing.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { of } from 'rxjs';
import { isUrlExpired } from 'app/core/lib/url-utils';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import {
  showPatientDocuments,
  showClientPrescriptionMatches,
  showValidateReferral,
  showValidatePrescriptionWrittenAtCe
} from 'app/core/lib/capture-nav-util';
import { displayCaptureStatus } from 'app/core/options/capture-status.opts';

const shouldReloadCapture = (capture: Capture, captureId) => capture && captureId !== capture.id.toString();

@Component({
  selector: 'app-capture-detail',
  templateUrl: './capture-detail.component.html',
  styleUrls: ['./capture-detail.component.scss'],
})
export class CaptureDetailComponent implements OnInit, OnDestroy {
  captureId: string;
  capture: Capture;
  patientPrescriber: PatientPrescriber;
  selectedAttachment: PatientAttachment = null;

  refreshing = true;
  captureStatuses = CaptureStatus;
  currentSection = null;
  showPatientDocumentsSection = true;
  showValidateReferralSection = true;
  showValidatePrescriptionWrittenAtCe = false;

  captureChangedSubscription = null;
  patientPrescriberChangedSubscription = null;
  patientAttachmentChangedSubscription = null;

  constructor(
    private route: ActivatedRoute,
    private captureService: CaptureService,
    private patientPrescriberService: PatientPrescriberService,
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private titleService: Title,
    private userTimingService: UserTimingService,
    private authService: AuthenticationService,
    private userPresenceService: UserPresenceService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.captureId = this.route.snapshot.paramMap.get('id');

    this.currentSection = 'validateReferral';

    this.loadCapture(this.captureId);

    this.captureChangedSubscription = this.captureService.captureChanged.subscribe(({ capture }) => {
      this.startTimeTracking(capture);
      // is it our capture?
      if (capture.id === this.capture.id) {
        this.capture = capture;
      }
    });

    this.patientPrescriberChangedSubscription = this.patientPrescriberService.patientPrescriberChanged.subscribe(
      ({ patientPrescriber }) => {
        if (patientPrescriber.id === this.patientPrescriber.id) {
          this.patientPrescriber = patientPrescriber;
        }
      }
    );

    this.patientAttachmentChangedSubscription = this.patientAttachmentsService.patientAttachmentChanged.subscribe(
      ({ patientAttachment }) => {
        // data change to the active PA used later for validating submit
        if (patientAttachment.id === this.selectedAttachment.id) {
          //console.log('selectedAttachment changed: \n', patientAttachment);
          this.selectedAttachment = patientAttachment;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.captureChangedSubscription) {
      this.captureChangedSubscription.unsubscribe();
    }

    if (this.patientPrescriberChangedSubscription) {
      this.patientPrescriberChangedSubscription.unsubscribe();
    }

    if (this.patientAttachmentChangedSubscription) {
      this.patientAttachmentChangedSubscription.unsubscribe();
    }

    this.stopTimeTracking();

    this.userPresenceService.leaveAndUnsubscribe("capture", this.captureId);
  }

  onSectionScroll(sectionId: string) {
    this.currentSection = sectionId;
  }

  onSectionClick(sectionId: string) {
    this.currentSection = sectionId;
    document.querySelector('#' + sectionId).scrollIntoView();
  }

  onAttachmentSelectionChange(newSelection: PatientAttachment) {
    // selection from the dropdown changed (i.e. 'active patient attachment')
    if (newSelection.url && isUrlExpired(newSelection.url)) {
      this.patientAttachmentsService.getUrl(newSelection.id).subscribe(({ url }) => {
        newSelection.url = url;
        this.selectedAttachment = newSelection;
      });
    } else {
      this.selectedAttachment = newSelection;
    }
  }

  private loadCapture(captureId): void {
    this.refreshing = true;
    this.captureService
      .getCapture(captureId)
      .pipe(
        mergeMap((capture: Capture) => {
          this.capture = capture;

          if (this.capture.verifyingPatientAttachmentId) {
            return this.patientAttachmentsService.get(this.capture.verifyingPatientAttachmentId);
          } else {
            return of(null);
          }
        }),
        mergeMap((patientAttachment: PatientAttachment) => {
          this.selectedAttachment = patientAttachment;

          return this.patientPrescriberService.get(this.capture.patientPrescriberId);
        }),
      )
      .subscribe(
        (patientPrescriber: PatientPrescriber) => {
          this.patientPrescriber = patientPrescriber;

          this.refreshing = false;

          this.showPatientDocumentsSection = showPatientDocuments(this.capture);
          this.showValidateReferralSection = showValidateReferral(this.capture);
          this.showValidatePrescriptionWrittenAtCe = showValidatePrescriptionWrittenAtCe(this.capture);

          this.setTitle();
          this.startTimeTracking(this.capture);
          this.userPresenceService.subscribeAndJoin("capture", this.captureId);
        },
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.navigationService.notFound();
          } else {
            console.error(err);
          }
        }
      );

    window.scrollTo(0, 0);
  }

  private setTitle() {
    status = displayCaptureStatus(this.capture.status);
    const title = `R1 340B Recovery - ${this.capture.candidate.claimIdentifier} | ${status}`;
    this.titleService.setTitle(title);
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const captureId = params && params['id'];

    if (shouldReloadCapture(this.capture, captureId)) {
      this.userPresenceService.leaveAndUnsubscribe("capture", this.captureId);
      this.captureId = captureId;
      this.loadCapture(captureId);
    }
  }

  private startTimeTracking(capture) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'capture_details',
        recordId: capture.id,
        status: capture.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }

  get showClientPrescriptionMatches(): boolean {
    return showClientPrescriptionMatches(this.capture);
  }
}
