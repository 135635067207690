import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isEmpty, debounce } from 'lodash-es';
import { PatientMatchVerification } from '../../../core/models/patient-match-verification.model';
import { PatientMatchVerificationsService } from '../../../core/services/patient-match-verifications.service';
import { Capture } from '../../../core/models/capture.model';
import { CaptureValidationService, ValidationKeys } from '../../../core/services/capture-validation.service';
import { isPatientAttachmentVerificationFrozen } from '../../../core/lib/verification-utils';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { handleSimpleChanges } from '../../../core/lib/component-utils';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';

@Component({
  selector: 'app-patient-match-form',
  templateUrl: './patient-match-form.component.html',
  styleUrls: ['./patient-match-form.component.scss'],
})
export class PatientMatchFormComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  showAdditionalDetails = false;
  validationKey = ValidationKeys.patientMatchVerification;
  verificationFrozen = false;
  patientMatchVerification: PatientMatchVerification = null;

  debouncedSaveVerification = debounce(
    () => {
      this.savePatientMatchVerification();
    },
    250,
    { maxWait: 1000 }
  );

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private patientMatchVerificationService: PatientMatchVerificationsService,
    private authService: AuthenticationService,
    private captureValidationService: CaptureValidationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture') {
        this.verificationFrozen = isPatientAttachmentVerificationFrozen(
          this.authService,
          this.capture,
          this.patientAttachment,
          this.patientMatchVerification
        );
      }

      if (inputName === 'patientAttachment') {
        this.initPatientMatchVerification();
      }
    });
  }

  onPatientMatchesChange() {
    this.captureValidationService.clearError(this.validationKey);
    this.debouncedSaveVerification();
  }

  onAdditionalTextChange() {
    this.debouncedSaveVerification();
  }

  onPlusClick() {
    this.showAdditionalDetails = true;
  }

  onMinusClick() {
    this.showAdditionalDetails = false;
  }

  private savePatientMatchVerification() {
    if (this.patientMatchVerification.id) {
      this.updatePatientMatchVerification();
    } else {
      this.createPatientMatchVerification();
    }
  }

  private createPatientMatchVerification() {
    this.patientMatchVerificationService
      .create(this.patientAttachment.id, this.capture.id, this.patientMatchVerification)
      .subscribe((patientMatchVerification: PatientMatchVerification) => {
        this.handleSave(patientMatchVerification);
      });
  }

  private updatePatientMatchVerification() {
    this.patientMatchVerificationService
      .update(
        this.patientAttachment.id,
        this.patientMatchVerification.id,
        this.capture.id,
        this.patientMatchVerification
      )
      .subscribe((patientMatchVerification: PatientMatchVerification) => {
        this.handleSave(patientMatchVerification);
      });
  }

  private handleSave(patientMatchVerification: PatientMatchVerification) {
    const modifiedPatientAttachment: PatientAttachment = Object.assign({}, this.patientAttachment);
    modifiedPatientAttachment.patientMatchVerification = patientMatchVerification;
    this.patientAttachmentsService.notifyPatientAttachmentChanged(modifiedPatientAttachment);
  }

  private initPatientMatchVerification() {
    this.patientMatchVerification = this.patientAttachment.patientMatchVerification || new PatientMatchVerification();
    this.verificationFrozen = isPatientAttachmentVerificationFrozen(
      this.authService,
      this.capture,
      this.patientAttachment,
      this.patientMatchVerification
    );
    this.showAdditionalDetails = !isEmpty(this.patientMatchVerification.notes);
  }
}
