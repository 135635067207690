<div *ngIf="!processing; else: spinner" ngbDropdown class="d-inline-block text-nowrap">
  <button id="actionDropdown" class="btn btn-secondary" ngbDropdownToggle>Select Action</button>
  <div ngbDropdownMenu aria-labelledby="actionDropdown">
    <a
      *ngIf="canUnlock"
      class="dropdown-item"
      href="#unlock"
      (click)="onUnlockClick($event)"
    >Unlock</a
    >
    <a
      *ngIf="canDeactivate"
      class="dropdown-item"
      href="#deactivate"
      (click)="onDeactivateClick($event)"
      >Deactivate</a
    >
    <a
      *ngIf="canReactivate"
      class="dropdown-item"
      href="#reactivate"
      (click)="onReactivateClick($event)"
      >Reactivate</a
    >
    <a
      *ngIf="canResetPassword"
      class="dropdown-item"
      href="#resetPassword"
      (click)="onResetPasswordClick($event)"
      >Reset Password</a
    >
    <a
      *ngIf="canResendInvitation"
      class="dropdown-item"
      href="#resendInvitation"
      (click)="onResendInvitationClick($event)"
      >Resend Invitation</a
    >
    <a
      *ngIf="canEdit"
      class="dropdown-item"
      routerLink="/capture-admin/users/{{user.id}}/edit"
      >Edit User</a
    >
    <a
      *ngIf="canViewChangeLog"
      class="dropdown-item"
      routerLink="/capture-admin/change-log/User/{{ user.id }}"
      >View Change Log</a
    >
  </div>
</div>
<ng-template #spinner>
  <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" /> Processing...
</ng-template>
