<form>
  <app-verification-validation [key]="validationKey">

    <div *ngIf="!patientAttachment.ehrConsultNoteReference">
      <span>Select providers who work at the office that is referenced on the consult note:</span>
      <div *ngIf="patientAttachment.outboundFaxNumber">
        <small><strong>OFFICE FAX:</strong> <app-office-fax *ngIf="patientAttachment.faxedOffice; else: noFaxedOffice" [office]="patientAttachment.faxedOffice" /></small>
      </div>
    </div>

    <div *ngIf="patientAttachment.ehrConsultNoteReference">
      Confirm the provider is referenced on the consult note:
    </div>

    <div *ngIf="prescriberMatchOptions.length > 0; else: noPrescribers" class="prescribers border p-2">
      <div
        *ngFor="let prescriberMatchOption of prescriberMatchOptions"
        class="prescriber form-check mb-1">
        <div [ngClass]="{ disabled: prescriberMatchOption.frozen }">
          <input
            type="checkbox"
            [disabled]="prescriberMatchOption.frozen"
            (change)="onPrescriberVerificationChange(prescriberMatchOption.verification)"
            [id]="prescriberMatchOption.npi"
            [name]="prescriberMatchOption.npi"
            class="form-check-input"
            [(ngModel)]="prescriberMatchOption.verification.matches"
          />
          <label [for]="prescriberMatchOption.npi" class="form-check-label">
            <div class="small">
              <strong>
                {{ prescriberMatchOption.name | blankValueDash }}
                ({{ prescriberMatchOption.status | providerOfficeFaxStatus }})
              </strong>
            </div>
            <div class="small">{{ prescriberMatchOption.specialty | blankValueDash }}</div>
            <div class="small">
              <strong>NPI:</strong> {{ prescriberMatchOption.npi }}
              <span *ngIf="prescriberMatchOption.isPrescriber">(Prescriber)</span>
            </div>
          </label>
        </div>
        <app-provider-office-association-notes
          *ngIf="prescriberMatchOption.providerOffice"
          [providerOfficeId]="prescriberMatchOption.providerOffice?.id"
          [enablePopover]="true" />
      </div>
    </div>
  </app-verification-validation>

  <div class="alert alert-warning p-1 mt-1" *ngIf="capturePrescriberNotAtOffice">
    Our records indicate that the prescriber does not practice at the office associated with the consult note.
  </div>

  <div *ngFor="let missingPrescriber of verifyingPrescribersNotAtOffice" class="alert alert-warning p-1 mt-1">
    Our records indicate that prescriber, {{ missingPrescriber.name | blankValueDash }} (NPI {{ missingPrescriber.npi }}) no
    longer practices at the office associated with the consult note.
  </div>
</form>

<div class="mb-1 mt-2">
  <app-additional-details-input
    [frozen]="additionalDetailsFrozen"
    [(value)]="patientAttachment.prescriberMatchVerificationNotes"
    (valueChange)="onAdditionalTextChange()"
    [rows]="5"
  />
</div>

<ng-template #noPrescribers>
  <div class="p-1">
    <small><strong>No Prescribers Found</strong></small>
  </div>
</ng-template>

<ng-template #noFaxedOffice>
  <app-phone-number [number]="patientAttachment.outboundFaxNumber" />
</ng-template>
