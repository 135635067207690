import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { environment } from '../../../../environments/environment';
import { ConsultNoteSelectSpecialtyComponent } from '../consult-note-select-specialty/consult-note-select-specialty.component';
import { ValidatePatientMatchComponent } from '../validate-patient-match/validate-patient-match.component';
import { ValidateSpecialistEncounterComponent } from '../validate-specialist-encounter/validate-specialist-encounter.component';
import { ValidatePrescriberMatchComponent } from '../validate-prescriber-match/validate-prescriber-match.component';
import { ValidateDrugMatchComponent } from '../validate-drug-match/validate-drug-match.component';

@Component({
  selector: 'app-consult-note-document',
  templateUrl: './consult-note-document.component.html',
  styleUrls: ['./consult-note-document.component.scss'],
})
export class ConsultNoteDocumentComponent implements OnInit {
  _patientAttachment: PatientAttachment;

  @Output() markedAsInvalidChanged = new EventEmitter<PatientAttachment>();

  markingAsInvalid = false;
  markAsInvalidDisabled = false;
  initialPage = null;
  activePage = null;
  usePdfKitViewer: boolean;
  canAdmin: boolean;

  @ViewChild('selectSpecialty', { static: true })
  selectSpecialty: ConsultNoteSelectSpecialtyComponent;

  @ViewChild('validatePatientMatch', { static: true })
  validatePatientMatch: ValidatePatientMatchComponent;

  @ViewChild('validateSpecialistEncounter', { static: true })
  validateSpecialistEncounter: ValidateSpecialistEncounterComponent;

  @ViewChild('validatePrescriberMatch', { static: true })
  validatePrescriberMatch: ValidatePrescriberMatchComponent;

  @ViewChild('validateDrugMatch', { static: true })
  validateDrugMatch: ValidateDrugMatchComponent;

  pdfFileType: PdfFileType = PdfFileType.patientAttachment;

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private authService: AuthenticationService) {}

  get patientAttachment(): PatientAttachment {
    return this._patientAttachment;
  }

  @Input()
  set patientAttachment(pa: PatientAttachment) {
    this._patientAttachment = pa;
    this.initialPage = (pa && pa.medicationsPage) || 1;
    this.activePage = this.initialPage;
    this.markAsInvalidDisabled = this.patientAttachment.hasVerifiedCapture;
  }

  public onPageChange(page) {
    this.activePage = page;
  }

  public onUrlChange(url: string) {
    this.patientAttachment.url = url;
  }

  onExpandAllClick($event) {
    $event.preventDefault();
    this.selectSpecialty.expand();
    this.validatePatientMatch.expand();
    this.validateSpecialistEncounter.expand();
    this.validatePrescriberMatch.expand();
    this.validateDrugMatch.expand();
  }

  onCollapseAllClick($event) {
    $event.preventDefault();
    this.selectSpecialty.collapse();
    this.validatePatientMatch.collapse();
    this.validateSpecialistEncounter.collapse();
    this.validatePrescriberMatch.collapse();
    this.validateDrugMatch.collapse();
  }

  onVerificationLinkClick($event, verificationPanelId) {
    $event.preventDefault();
    const verificationElement = document.getElementById(verificationPanelId);
    verificationElement.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
  }

  onMarkedAsInvalidChanged(pa: PatientAttachment) {
    this.patientAttachment = pa;
  }

  onMarkAsInvalidClick($event) {
    $event.preventDefault();
    this.markingAsInvalid = true;
    const invalid = !this.patientAttachment.markedAsInvalid;

    this.patientAttachmentsService.markAsInvalid(this.patientAttachment.id, invalid, true).subscribe(
      pa => {
        this.markedAsInvalidChanged.emit(pa);
        this.markingAsInvalid = false;
      },
      () => {
        this.markingAsInvalid = false;
      }
    );
  }

  ngOnInit() {
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
    this.canAdmin = this.authService.isCaptureAdminUser;
  }
}
