import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { OutreachAttemptEvidence } from '../models/outreach-attempt-evidence.model';

export class OutreachAttemptEvidenceParams {
  public patientPrescriberId?: number;
  public officeIds: number[];
  public lastOutreachDate: Date;
  public notes: string;
}

@Injectable()
export class OutreachAttemptEvidenceService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(
    outreachAttemptEvidenceParams: OutreachAttemptEvidenceParams
  ): Observable<{ outreachAttemptEvidence: OutreachAttemptEvidence }> {
    return this.http.post<{ outreachAttemptEvidence: OutreachAttemptEvidence }>(
      `${environment.captureApi.url}/outreach_attempt_evidence`,
      { outreachAttemptEvidence: outreachAttemptEvidenceParams }
    );
  }

  update(
    id: number,
    outreachAttemptEvidenceParams: OutreachAttemptEvidenceParams
  ): Observable<{ outreachAttemptEvidence: OutreachAttemptEvidence }> {
    return this.http.patch<{ outreachAttemptEvidence: OutreachAttemptEvidence }>(
      `${environment.captureApi.url}/outreach_attempt_evidence/${id}`,
      { outreachAttemptEvidence: outreachAttemptEvidenceParams }
    );
  }
}
