<div id="selectSpecialty" class="card mb-1">
  <app-validation-header
    [icon]="'medical_information'"
    [title]="'Confirm Consult Note Specialty'"
    [collapsed]="collapsed"
    (collapseChange)="onCollapseChange($event)"
  />
  <div class="card-body p-1">
    <div *ngIf="!collapsed" class="my-1">Select the medical specialty that is most closely related to this consult note</div>
    <ng-select
      placeholder="Choose a specialty"
      class="mb-1" [ngClass]="{ 'border border-2 border-danger-subtle rounded': !patientAttachment.selectedSpecialty }"
      [items]="referralSpecialties"
      bindValue="name"
      bindLabel="name"
      [readonly]="patientAttachment.selectedSpecialtyFrozen"
      [(ngModel)]="patientAttachment.selectedSpecialty"
      (ngModelChange)="onSelectedSpecialtyChange()"
    />
    <app-additional-details-input
      id="selectedSpecialtyAdditionalDetails"
      [frozen]="patientAttachment.selectedSpecialtyFrozen"
      [(value)]="patientAttachment.selectedSpecialtyAdditionalDetails"
      (valueChange)="onAdditionalDetailsChanged()"
    />
  </div>
</div>
