import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Prescriber } from 'app/core/models/prescriber.model';
import { ProviderOffice } from 'app/core/models/provider-office.model';
import { ProviderService } from 'app/core/services/provider.service';
import { OutreachAttemptEvidence } from 'app/core/models/outreach-attempt-evidence.model';
import { keyedStatusOptions as keyedOfficeStatusOptions } from 'app/core/options/office-status.opts';
import { keyedStatusOptions as keyedProviderOfficeStatusOptions } from 'app/core/options/provider-office-status.opts';
import { ProviderOfficeStatus } from 'app/core/enums/provider-office-status.enum';
import { OutreachAttemptEvidenceParams, OutreachAttemptEvidenceService } from 'app/core/services/outreach-attempt-evidence.service';
import { CaptureValidationService, ValidationKeys } from 'app/core/services/capture-validation.service';
import { Capture } from 'app/core/models/capture.model';

@Component({
  selector: 'app-outreach-attempt-evidence-form',
  templateUrl: './outreach-attempt-evidence-form.component.html',
  styleUrls: ['./outreach-attempt-evidence-form.component.scss'],
})
export class OutreachAttemptEvidenceFormComponent implements OnInit {
  @Input() capture: Capture;
  @Input() prescriber: Prescriber;
  @Input() patientPrescriberId: number;

  @Output() newOutreachAttemptEvidence = new EventEmitter<OutreachAttemptEvidence>();
  @Output() canceled = new EventEmitter<void>();

  loading = true;
  formValid = false;

  outreachAttemptEvidence = new OutreachAttemptEvidence();
  formGroup: FormGroup;
  providerOffices: ProviderOffice[] = [];

  keyedOfficeStatusOptions = keyedOfficeStatusOptions;
  keyedProviderOfficeStatusOptions = keyedProviderOfficeStatusOptions;

  validationKey = ValidationKeys.verifyingOutreachAttemptEvidence;

  constructor(
    private provideerService: ProviderService,
    private outreachAttemptEvidenceService: OutreachAttemptEvidenceService,
    private formBuilder: FormBuilder,
    private captureValidationService: CaptureValidationService,
  ) { }

  onCancelClick() {
    this.canceled.emit();
  }

  onSubmit() {
    const params = this.prepareFormParams();

    params.patientPrescriberId = this.patientPrescriberId;

    const validDate =
      this.captureValidationService.validateVerifyingOutreachAttemptEvidenceDate(
        this.capture,
        params.lastOutreachDate
      );

    if (validDate) {
      this.outreachAttemptEvidenceService.create(params).
        subscribe(({ outreachAttemptEvidence: outreachAttemptEvidence }) => {
          this.newOutreachAttemptEvidence.emit(outreachAttemptEvidence);
        });
    }
  }

  ngOnInit() {
    this.provideerService.getProviderOffices(this.prescriber.providerId).
      subscribe(providerOffices => {
        this.providerOffices = providerOffices;
        this.setupForm();
        this.loading = false;
      });
  }

  officeSelectedInArray(index: number) {
    const officesFormArray = this.formGroup.get('offices') as FormArray;
    return !!officesFormArray.get(index.toString())?.value;
  }

  private setupForm() {
    const officeOptions = this.providerOffices.map((providerOffice: ProviderOffice) => {
      return {
        value: false,
        disabled: providerOffice.status !== ProviderOfficeStatus.verified
      }
    });

    this.formGroup = this.formBuilder.group({
      lastOutreachDate: [],
      notes: [],
      offices: this.formBuilder.array(officeOptions)
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.formValid = this.validateForm();
    });
  }

  private prepareFormParams(): OutreachAttemptEvidenceParams {
    const formValue = this.formGroup.value;

    const officeIds = [];
    this.providerOffices.forEach((providerOffice: ProviderOffice, index: number) => {
      if (this.officeSelectedInArray(index)) {
        officeIds.push(providerOffice.office.id);
      }
    });

    return {
      lastOutreachDate: formValue.lastOutreachDate,
      notes: formValue.notes,
      officeIds
    };
  }

  private validateForm() {
    const formValue = this.formGroup.value;

    return formValue.lastOutreachDate &&
      formValue.notes &&
      formValue.notes.length > 0 &&
      formValue.offices.some((v: boolean) => v);
  }
}
