<div class="mb-0">
  <label [for]="id" class="mb-0">ADDITIONAL DETAIL</label>
  <fa-icon *ngIf="!show" [icon]="['fas', 'plus-square']" size="sm" (click)="onPlusClick()" />
  <fa-icon *ngIf="show" [icon]="['fas', 'minus-square']" size="sm" (click)="onMinusClick()" />
  <textarea
    *ngIf="show"
    [id]="id"
    [name]="id"
    class="form-control py-0 px-1"
    [disabled]="frozen"
    (change)="onAdditionalTextChange()"
    [(ngModel)]="value"
    [rows]="rows"
  >
  </textarea>
</div>
