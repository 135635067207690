<form *ngIf="drugMatchVerification">
  <app-verification-validation [key]="validationKey">
    <div>Does the consult note reference the claim RX or class of RX?</div>
    <div class="form-inline">
      <div class="form-check form-check-inline">
        <input
          id="drugMatchVerificationMatchesYes"
          name="drugMatchVerificationMatchesYes"
          class="form-check-input"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onDrugMatchesChange()"
          [(ngModel)]="drugMatchVerification.matches"
          [value]="true"
        />
        <label class="form-check-label" for="drugMatchVerificationMatchesYes">Yes</label>
      </div>
      <div class="form-check form-check-inline mx-2">
        <input
          id="drugMatchVerificationMatchesNo"
          name="drugMatchVerificationMatchesNo"
          class="form-check-input"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onDrugMatchesChange()"
          [(ngModel)]="drugMatchVerification.matches"
          [value]="false"
        />
        <label class="form-check-label" for="drugMatchVerificationMatchesNo">No</label>
      </div>
      <div class="alert alert-warning mt-1" *ngIf="patientAttachment.drugMatchNotRequired">
        This client does not require that the drug be referenced on the consult note. If the drug is not referenced you may select <em>No</em> and verify the capture.
      </div>
    </div>
  </app-verification-validation>
</form>
<div class="mb-1 mt-2">
  <app-additional-details-input
    [frozen]="verificationFrozen"
    [(value)]="drugMatchVerification.notes"
    (valueChange)="onAdditionalTextChange()"
    [rows]="5"
  />
</div>
