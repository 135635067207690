import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {
  EhrReferralVerificationsService,
  EhrReferralVerificationParams,
  EhrReferralVerificationUpdate
} from "app/core/services/ehr-referral-verifications.service";
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReferralVerificationReason } from 'app/core/enums/referral-verification-reason.enum';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { Prescriber } from 'app/core/models/prescriber.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateUtils } from 'app/core/lib/date-utils';
import { ProviderSpecialtyService } from 'app/core/services/provider-specialty.service';
import { ReferralSpecialty } from 'app/core/models/referral-specialty.model';

@Component({
  selector: 'app-ehr-referral-verification-form',
  templateUrl: './ehr-referral-verification-form.component.html',
  styleUrls: ['./ehr-referral-verification-form.component.scss'],
})
export class EhrReferralVerificationsFormComponent implements OnInit {

  @Input() patientPrescriber: PatientPrescriber;
  @Input() prescriber: Prescriber;
  @Input() ehrReferralVerification: EhrReferralVerification;
  @Output() canceled = new EventEmitter<void>();
  @Output() saved = new EventEmitter<EhrReferralVerificationUpdate>();

  formGroup: FormGroup;
  editing = true;
  referralSpecialties: ReferralSpecialty[];
  serverError: string[];

  constructor(
    public ehrReferralVerificationsService: EhrReferralVerificationsService,
    private formBuilder: FormBuilder,
    private providerSpecialtyService: ProviderSpecialtyService
  ) {
  }

  ngOnInit() {
    this.providerSpecialtyService.getReferralSpecialties().subscribe(
      specialties => this.referralSpecialties = specialties
    );

    if (!this.ehrReferralVerification) {
      this.ehrReferralVerification = new EhrReferralVerification();
      this.editing = false;
    }

    this.initForm();
  }

  formIsValid() {
    const formValue = this.formGroup.value;

    if (!formValue.referralDate) {
      return false;
    }

    if (!formValue.reasons.prescriberReferral &&
      !formValue.reasons.specialityReferral &&
      !formValue.reasons.prescriberOfficeReferral &&
      !formValue.reasons.other) {
      return false;
    }

    if (this.shouldHaveChoiceSpecialityReferralSpeciality() && !formValue.reasons.referralSpecialty) {
      return false;
    }

    if (this.shouldHaveChoiceOtherReasonNote() && !formValue.reasons.otherReason) {
      return false;
    }

    return true;
  }

  onSaveClick() {
    const params = this.formValueParams();
    const id = this.ehrReferralVerification.id;

    delete this.serverError;

    let request: Observable<EhrReferralVerificationUpdate>;

    if (id) {
      request = this.ehrReferralVerificationsService.update(id, params);
    } else {
      params.patientPrescriberId = this.patientPrescriber.id;

      request = this.ehrReferralVerificationsService.create(params);
    }

    request.subscribe(
      (result: EhrReferralVerificationUpdate) => {
        this.saved.emit(result);
      },
      (response: HttpErrorResponse) => {
        this.serverError = response.error.errors.join(", ");
      }
    )
  }

  onCancelClick() {
    this.canceled.emit();
  }

  private ehrReferralVerificationReferralDateString() {
    const referralDate = this.ehrReferralVerification.referralDate;

    if (referralDate) {
      return DateUtils.toServerFormat(new Date(referralDate));
    }
  }

  private ehrReferralVerificationReasonsToGroupValues() {
    const entries =
      Object.keys(ReferralVerificationReason).
        map(reason => [reason, this.ehrReferralVerification.reasons.includes(ReferralVerificationReason[reason])]);

    return Object.fromEntries(entries);
  }

  private initForm() {
    const referralSpecialty =
      this.ehrReferralVerification.referralSpecialty || this.prescriber.recommendedReferralSpecialty;

    this.formGroup = this.formBuilder.group({
      reasons: this.formBuilder.group({
        ...this.ehrReferralVerificationReasonsToGroupValues(),
        referralSpecialty: [referralSpecialty],
        otherReason: [this.ehrReferralVerification.otherReason]
      }),
      referralDate: [this.ehrReferralVerificationReferralDateString()],
      notes: [this.ehrReferralVerification.notes]
    });
  }

  private formValueParams(): EhrReferralVerificationParams {
    const formValue = this.formGroup.value;

    const reasons =
      Object.keys(ReferralVerificationReason).
        map(reason => formValue.reasons[reason] ? ReferralVerificationReason[reason] : null).
        filter(Boolean);

    const params = {
      reasons,
      otherReason: null,
      referralSpecialty: null,
      referralDate: formValue.referralDate,
      notes: formValue.notes
    };

    if (this.shouldHaveChoiceSpecialityReferralSpeciality()) {
      params.referralSpecialty = formValue.reasons.referralSpecialty;
    }

    if (this.shouldHaveChoiceOtherReasonNote()) {
      params.otherReason = formValue.reasons.otherReason;
    }

    return params;
  }

  shouldHaveChoiceSpecialityReferralSpeciality() {
    return this.formGroup.value.reasons.specialityReferral;
  }

  shouldHaveChoiceOtherReasonNote() {
    return this.formGroup.value.reasons.other;
  }
}
