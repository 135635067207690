<div class="wrapper">
  <nav class="sidebar" [ngClass]="{ collapsed: sidebarCollapsed }">
    <div class="sidebar-header text-black-50">
      <div class="user-name">{{ userName }}</div>
      <a href="#toggleCollapsed" class="toggle" (click)="onToggleCollapseClick($event)">
        <mat-icon *ngIf="!sidebarCollapsed">menu_open</mat-icon>
        <mat-icon *ngIf="sidebarCollapsed">menu</mat-icon>
      </a>
    </div>

    <h3 class="sidebar-subheader" *ngIf="!sidebarCollapsed && canAccessReferralClaims">
      340B Referral Capture
    </h3>

    <ul class="list-unstyled" *ngIf="canAccessReferralClaims">
      <li *ngIf="myWorkPagesEnabled" [ngClass]="{ active: activeSections['my-work'] }">
        <a routerLink="my-work/faxes" [ngClass]="{ active: activeSections['my-work'] }">
          <mat-icon>folder_shared</mat-icon> <span>My Work</span>
        </a>
        <ul class="list-unstyled active pt-1">
          <li *ngIf="myWorkFaxesEnabled">
            <a routerLinkActive="active" routerLink="my-work/faxes">
              <span class="link-with-count">
                <span class="link-label">My Faxes</span>
                <span class="link-count" *ngIf="myWorkCounts.faxes">{{ myWorkCounts.faxes | countWithMax: 99 }}</span>
              </span>
            </a>
          </li>
          <li *ngIf="myWorkConsultNotesEnabled">
            <a routerLinkActive="active" routerLink="my-work/consult-notes">
              <span class="link-with-count">
                <span class="link-label">My Consult Notes</span>
                <span class="link-count" *ngIf="myWorkCounts.consultNotes">{{ myWorkCounts.consultNotes | countWithMax: 99 }}</span>
              </span>
            </a>
          </li>
          <li *ngIf="myWorkClientChoresEnabled">
            <a routerLinkActive="active" routerLink="my-work/client-chores">
              <span class="link-with-count">
                <span class="link-label">My Client Chores</span>
                <span class="link-count" *ngIf="myWorkCounts.clientChores">{{ myWorkCounts.clientChores | countWithMax: 99 }}</span>
              </span>
            </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{ active: activeSections.captures }">
        <a routerLink="captures" [ngClass]="{ active: activeSections.captures }">
          <mat-icon>featured_play_list</mat-icon> <span>Captures </span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections.captures">
          <li><a routerLinkActive="active" routerLink="internal-consultant-captures"> Internal Consultant Queue </a></li>
        </ul>
      </li>
      <li>
        <a routerLink="capture-search" routerLinkActive="active"> <mat-icon>search</mat-icon> <span>Search </span> </a>
      </li>
      <li *ngIf="consultNotePagesEnabled">
        <a routerLink="consult-notes" routerLinkActive="active">
          <mat-icon class="material-icons-round">file_present</mat-icon> <span>Consult Notes </span>
        </a>
      </li>
      <li [ngClass]="{ active: activeSections.communications }">
        <a routerLink="communications/inbound-faxes" [ngClass]="{ active: activeSections.communications }">
          <mat-icon>forum</mat-icon> <span>Communications </span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections.communications">
          <li><a routerLinkActive="active" routerLink="communications/inbound-faxes"> Inbound Fax Queue </a></li>
          <li><a routerLinkActive="active" routerLink="communications/inbound-calls"> Inbound Call Log </a></li>
          <li>
            <a routerLinkActive="active" routerLink="communications/faxable-patient-prescriber-reports"> Outgoing Fax Report</a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="communications/import-outbound-faxes"> Outgoing Fax Import </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="communications/legacy-import-outbound-faxes"> Legacy Outgoing Fax Import </a>
          </li>
        </ul>
      </li>
      <li [ngClass]="{ active: activeSections.reports }">
        <a routerLink="reports/capture-billing-state-reports" [ngClass]="{ active: activeSections.reports }">
          <mat-icon>bar_chart</mat-icon> <span>Reports </span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections.reports">
          <li>
            <a routerLinkActive="active" routerLink="reports/capture-billing-state-reports"> Capture Billing Report </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="reports/capture-invoice-line-item-reports"> Capture Invoice Report </a>
          </li>
          <li><a routerLinkActive="active" routerLink="reports/capture-reports"> Capture Report </a></li>
          <li>
            <a routerLinkActive="active" routerLink="reports/missing-ndc-reports">
              NDCs with Missing Data Report
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="reports/partner-portal-claim-reports">
              Partner Portal Claim Report
            </a>
          </li>
          <li><a routerLinkActive="active" routerLink="reports/user-timing-reports"> User Timing Report </a></li>
          <li>
            <a routerLinkActive="active" routerLink="reports/specialty-store-claim-patient-match-reports">
              WAG Specialty Store Claim Match Report
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="reports/patient-interaction-location-reports">
              Patient Interaction Location Report
            </a>
          </li>
        </ul>
      </li>

      <li [ngClass]="{ active: activeSections['referral-claims/clients'] }">
        <a routerLink="referral-claims/clients/client-prescriber-tasks" [ngClass]="{ active: activeSections['referral-claims/clients'] }">
          <mat-icon>business</mat-icon> <span>Clients</span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections['referral-claims/clients']">
          <li>
            <a routerLinkActive="active" routerLink="referral-claims/clients/client-prescriber-tasks"> Client Prescriber Tasks </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="referral-claims/clients/client-consult-note-tasks"> Client Consult Note Tasks </a>
          </li>
          <li><a routerLinkActive="active" routerLink="referral-claims/clients/client-referral-tasks"> Client Referral Tasks </a></li>
          <li>
            <a routerLinkActive="active" routerLink="referral-claims/clients/consult-notes">
              Consult Notes Requiring Manual Download
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="referral-claims/clients/direct-consult-notes">
              Consult Notes Sent Via Direct Message
            </a>
          </li>
        </ul>
      </li>

      <li [ngClass]="{ active: activeSections.tools }">
        <a routerLink="tools/client-files" [ngClass]="{ active: activeSections.tools }">
          <mat-icon>build</mat-icon> <span>Settings and Tools </span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections.tools">
          <li><a routerLinkActive="active" routerLink="tools/client-files"> Client File Monitoring </a></li>
          <li><a routerLinkActive="active" routerLink="tools/partner-files"> Partner File Monitoring </a></li>
          <li><a routerLinkActive="active" routerLink="tools/file-check"> File Checker </a></li>
          <li>
            <a routerLinkActive="active" routerLink="tools/referral-prescriber-specialty-mapping">
              Referral Prescriber Specialty Mapping
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/curated-specialties">
              Curated Specialties
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/import-capture-invoice-line-items">
              Import Capture Invoices and Credits
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/import-claims"> Import Claims </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/import-patient-interaction-locations">
              Import Eligible Locations
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/prescriber-incorrect-information-requests">
              Provider Info Requests
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/patient-match-approvals">
              Patient Match Approvals
            </a>
          </li>
          <li *ngIf="canManageClientChores">
            <a routerLinkActive="active" routerLink="tools/client-chores">
              Client Chores
            </a>
          </li>
          <li>
            <a routerLinkActive="active" routerLink="tools/llm-playground/projects">
              LLM Playground
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a routerLink="patients" routerLinkActive="active">
          <mat-icon>airline_seat_flat</mat-icon> <span>Patients </span>
        </a>
      </li>

      <li [ngClass]="{ active: activeSections.providers }">
        <a routerLink="providers/all" [ngClass]="{ active: activeSections.providers }">
          <mat-icon class="mat-icon me-2" fontSet="material-symbols-rounded">stethoscope</mat-icon>
          <span>Providers</span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections.providers">
          <li><a routerLinkActive="active" routerLink="providers/all">Provider List</a></li>
          <li><a routerLinkActive="active" routerLink="providers/mechanical-turk-reviews">Mechanical Turk Reviews</a></li>
          <li><a routerLinkActive="active" routerLink="providers/provider-offices/status-reviews">Validate Provider Fax Status</a></li>
        </ul>
      </li>

      <li [ngClass]="{ active: activeSections.offices }">
        <a routerLink="offices/all" [ngClass]="{ active: activeSections.offices }">
          <fa-icon class="me-2" [icon]="['fas', 'hospital']" />
          <span>Offices</span>
        </a>

        <ul class="list-unstyled active" *ngIf="activeSections.offices">
          <li><a routerLinkActive="active" routerLink="offices/all">Office List</a></li>
          <li><a routerLinkActive="active" routerLink="offices/status-reviews">Validate Office Fax Status</a></li>
        </ul>
      </li>
    </ul>

    <h3 class="sidebar-subheader" *ngIf="!sidebarCollapsed && canAccessIccClaims">340B Ineligible Claims Capture</h3>
    <hr *ngIf="sidebarCollapsed && canAccessReferralClaims && canAccessIccClaims" />

    <ul class="list-unstyled" *ngIf="canAccessIccClaims">

      <li>
        <a routerLink="icc-claims/captures" routerLinkActive="active">
          <mat-icon>featured_play_list</mat-icon> <span>Captures</span>
        </a>
      </li>

      <li [ngClass]="{ active: activeSections['icc-claims/imports'] }">
        <a routerLink="icc-claims/imports/claims"
           [ngClass]="{ active: activeSections['icc-claims/imports'] }">

          <mat-icon>file_upload</mat-icon> <span>Imports</span>
        </a>

        <ul class="list-unstyled active" *ngIf="activeSections['icc-claims/imports']">
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/claims">Claims</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/capture-eligibility-findings">Claim Findings</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/cataloged-drugs">Drug Catalog</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/eligible-providers">Eligible Providers</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/ineligible-drugs">Ineligible Drugs</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/medicaid-codes">Medicaid Codes</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/orphan-exemption-drugs">Orphan Exemptions</a></li>
          <li><a routerLinkActive="active" routerLink="icc-claims/imports/pharmacies">Pharmacies</a></li>
        </ul>
      </li>

      <li>
        <a routerLink="icc-claims/exports/claims"
           [ngClass]="{ active: activeSections['icc-claims/exports'] }">

          <mat-icon>file_download</mat-icon> <span>Exports</span>
        </a>

        <ul class="list-unstyled active" *ngIf="activeSections['icc-claims/exports']">
          <li><a routerLinkActive="active" routerLink="icc-claims/exports/claims">Eligible Claims</a></li>
        </ul>
      </li>

    </ul>

    <hr>

    <ul class="list-unstyled">

      <li [ngClass]="{ active: activeSections['client-management'] }">
        <a routerLink="client-management/clients" [ngClass]="{ active: activeSections['client-management']}">
          <mat-icon>business</mat-icon> <span>Client Management</span>
        </a>
        <ul class="list-unstyled active" *ngIf="activeSections['client-management']">
          <li>
            <a routerLinkActive="active"
              routerLink="client-management/clients">
              Client List
            </a>
          </li>
          <li>
            <a routerLinkActive="active"
              routerLink="client-management/patient-interaction-locations"
              *ngIf="canManageClients">
              340 Client Locations
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="canManageUsers">
        <a routerLink="users" routerLinkActive="active"> <mat-icon>person</mat-icon> <span>User Management </span> </a>
      </li>
    </ul>
  </nav>
  <div class="content">
    <div class="container-fluid pt-2"><router-outlet /></div>
  </div>
</div>
