<div class="card mt-1">
  <div class="card-body">
    <app-verification-heading
      [patientAttachment]="patientAttachment"
      title="Confirm Consult Note Specialty"
    />
    <div class="my-1">Select the medical specialty that is most closely related to this consult note</div>
    <ng-select
      placeholder="Choose a specialty"
      class="mb-2" [ngClass]="{ 'border border-2 border-danger-subtle rounded': !patientAttachment.selectedSpecialty }"
      [items]="referralSpecialties"
      bindValue="name"
      bindLabel="name"
      [readonly]="patientAttachment.selectedSpecialtyFrozen"
      [(ngModel)]="patientAttachment.selectedSpecialty"
      (ngModelChange)="onSelectedSpecialtyChange()"
    />
    <app-additional-details-input
      id="selectedSpecialtyAdditionalDetails"
      [frozen]="patientAttachment.selectedSpecialtyFrozen"
      [(value)]="patientAttachment.selectedSpecialtyAdditionalDetails"
      (valueChange)="onAdditionalDetailsChanged()"
    />
  </div>
</div>
