import { Component, Input } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { ReferralSpecialty } from 'app/core/models/referral-specialty.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { ProviderSpecialtyService } from 'app/core/services/provider-specialty.service';
import { debounce } from 'lodash-es';

@Component({
  selector: 'app-consult-note-select-specialty',
  templateUrl: './consult-note-select-specialty.component.html',
  styleUrl: './consult-note-select-specialty.component.scss'
})
export class ConsultNoteSelectSpecialtyComponent {
  @Input() capture: Capture;
  @Input() patientAttachment: PatientAttachment;

  collapsed = false;
  referralSpecialties: ReferralSpecialty[];

  constructor(
    private providerSpecialtyService: ProviderSpecialtyService,
    private patientAttachmentsService: PatientAttachmentsService
  ) {
    this.providerSpecialtyService.getReferralSpecialties().subscribe(
      specialties => this.referralSpecialties = specialties
    )
  }

  debouncedSavePatientAttachment = debounce(() => this.savePatientAttachment(), 250, { maxWait: 1000 });

  onSelectedSpecialtyChange() {
    this.debouncedSavePatientAttachment()
  }

  onAdditionalDetailsChanged() {
    this.debouncedSavePatientAttachment();
  }

  onCollapseChange(collapse: boolean) {
    this.collapsed = collapse;
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }

  private savePatientAttachment() {
    this.patientAttachmentsService.update(this.patientAttachment).subscribe((_patientAttachment: PatientAttachment) =>
      this.patientAttachmentsService.notifyPatientAttachmentChanged(this.patientAttachment)
    )
  }
}
